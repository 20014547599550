import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Form, Input, Checkbox, Button, Select, Col } from 'antd-min';
import { GLForm, GLFormComponentProps, FormItemsLayout, FormHelper, ComparisonOperator, GLGlobal, alignPop } from 'gl-commonui';
import { ContentVersionModel } from '@app/service/admin/contentversion';
import { GlobalProductModel, GlobalProductModelProps } from '@app/service/admin/products/model';
import { GSAdminLocale, SchoolLocale } from '@app/locales/localeid';
import { GLModal } from '@app/components/school/gl-modal';
import { SubmitBtns } from '@app/components';
import { GSAdminAction } from '@app/util';
import { goToList } from '@app/states/school/campus';

export interface GlobalProductDetailProps {
    // contentVersions: ContentVersionModel[]
    model?: GlobalProductModel
    isEdit?: boolean
    handleSubmit: (e) => void
    handleDelete?: (e) => void
    goToList: () => void
}

interface GlobalProductDetailStates {
    deletePromptVisible: boolean
}

@GLForm.create()
export class GlobalProductDetail extends Component<GlobalProductDetailProps & GLFormComponentProps, GlobalProductDetailStates> {
    formatMessage = GLGlobal.intl.formatMessage
    constructor(props) {
        super(props);
        this.state = { deletePromptVisible: false };
        this.handleDelete = this.handleDelete.bind(this);
    }

    // getOption(key, value, text) {
    //     return <Select.Option key={key} value={value}>{text}</Select.Option>
    // }

    // getContentOptions(contents) {
    //     return contents.map((content, index)=> {
    //         return this.getOption(index, content.id, content.name)
    //     });
    // }

    handleDelete(e) {
        e.preventDefault();
        this.setState({ deletePromptVisible: true });
    }

    render() {
        const { form, model = {}, isEdit, handleSubmit, goToList } = this.props;
        const renderFormItem = FormHelper.renderFormItem;
        const options = {
            formItemProps: { label: null },
            decoratorOptions: { valuePropName: 'checked' }
        }
        return (
            <GLForm form={form} onSubmit={onSubmit(this, handleSubmit)}>
                <FormItemsLayout colTotal={2}>
                    {renderFormItem(form, GSAdminLocale.ProductName, GlobalProductModelProps.name, <Input />, model.name, true, lengthValider(GSAdminLocale.ProductName, 200))}
                    {renderFormItem(form, GSAdminLocale.ProductUnit, GlobalProductModelProps.unit, <Input />, model.unit, false, lengthValider(GSAdminLocale.ProductUnit, 50))}
                </FormItemsLayout>
                {/* <FormItemsLayout colTotal={2}>
                    {renderFormItem(form, GSAdminLocale.GlobalProductContentVersion, GlobalProductModelProps.versionGroupId, 
                        <Select size='large' {...alignPop()}>{this.getContentOptions(contentVersions)}</Select>, model.versionGroupId, true)}
                    {renderFormItem(form, GSAdminLocale.ProductUnit, GlobalProductModelProps.unit, <Input />, model.unit, false, lengthValider(GSAdminLocale.ProductUnit, 50))}
                </FormItemsLayout> */}
                {isEdit && renderFormItem({ ...form, ...options }, GSAdminLocale.ProductDisabled, GlobalProductModelProps.disabled,
                    <Checkbox onChange={(e) => { model.disabled = e.target.checked; }}>
                        <FormattedMessage id={GSAdminLocale.ProductDisabled} />
                    </Checkbox>, model.disabled)}
                <SubmitBtns submitAction={GSAdminAction.EditGlobalProduct} onCancel={goToList} />
            </GLForm>
        );
    }
}

function lengthValider(localeId, rigthVal) {
    return [FormHelper.ruleForCompareLength(localeId, ComparisonOperator.LessOrEqualsThan, rigthVal)];
}

function onSubmit(component, handleSubmit) {
    return e => {
        e.preventDefault();
        const { props: { form, model } } = component;
        form.validateFields((err, values) => {
            if (!err) {
                handleSubmit({ ...model, ...values });
            }
        });
    }
}