import React, { Component } from 'react';
import { RouteComponentProps } from "react-router-dom";
import { withRouter, connect, GLGlobal, GLAction } from 'gl-commonui';
import { GLPageTitle } from '@app/components/admin/gl-pagetitle';
import { GlobalProductDetail } from './components/global-detail';
import { StateType } from '@app/states';
import { ContentVersionModel } from '@app/service/admin/contentversion';
import { GlobalProductModel } from '@app/service/admin/products/model';
import { getGlobalProduct, updateGlobalProduct, removeGlobalProduct, goToGlobalProductList } from '@app/states/admin/product';
import { GSAdminLocale, SchoolLocale } from '@app/locales/localeid';
import { SecondaryTitle } from '@app/components';
import { PageTitleDelBtn } from '@app/components/school/school-page-title-btn';
import { GSAdminAction } from '@app/util';

interface GlobalProductEditPageProps {
   // contentVersions: ContentVersionModel[]
    model: GlobalProductModel
    //getContentVersions: () => void
    getGlobalProduct: (id) => void
    updateGlobalProduct: (product) => void
    removeGlobalProduct: (id) => void
    goToGlobalProductList: () => void
}

@withRouter
@connect(({ product: { globalModel: model } }: StateType) => ({
    //contentVersions,
    model,
}), {
    //getContentVersions,
    getGlobalProduct,
    updateGlobalProduct,
    removeGlobalProduct,
    goToGlobalProductList
})
export class GlobalProductEditPage extends Component<RouteComponentProps<any> & GlobalProductEditPageProps> {
    componentDidMount() {
        const { globalProductId: productId } = this.props.match.params;
        // this.props.getContentVersions();
        this.props.getGlobalProduct(productId);
    }
    render() {
        const { model, updateGlobalProduct, removeGlobalProduct, goToGlobalProductList, match: { params: { globalProductId: id } } } = this.props;
        return (
            <div className='content-layout'>
                <div className="product-detail product-detail-edit">
                    <SecondaryTitle title={GSAdminLocale.LocalProductEditTitle} >
                        <PageTitleDelBtn
                            type="delete"
                            className="delete"
                            labelId={SchoolLocale.BtnDelete}
                            deleteTitleId={GSAdminLocale.LocalProductModelDelete}
                            delete={() => removeGlobalProduct(id)}
                        />
                    </SecondaryTitle>
                    <GlobalProductDetail
                        // contentVersions = {contentVersions}
                        model={model}
                        isEdit={true}
                        handleSubmit={(product) => updateGlobalProduct(product)}
                        goToList={goToGlobalProductList}
                    />
                </div>
            </div>
        )
    }
}